export function isNil(value: any): boolean {
  return value === null || value === undefined
}

export function isNotNil(value: any): boolean {
  return value !== null && value !== undefined
}

export function getRevalidateInterval(): number {
  return parseInt(process.env.NEXT_PUBLIC_REVALIDATE_INTERVAL ?? '900')
}
