import Image from 'next/image'
import { useUser } from '../../hooks/users/useUser'
import SignOffIcon from '../../public/static/avatar/logout.svg'
import DashboardIcon from '../../public/static/avatar/dashboard.svg'
import HeartIcon from '../../public/static/heart-black.svg'
import BlockedIcon from '../../public/static/blocked-teammates-black.svg'
import BalanceIcon from '../../public/static/avatar/credit.svg'
import MenuIcon from '../../public/static/menu.svg'
import WalletIcon from '../../public/static/avatar/wallet.svg'
import PeopleIcon from '../../public/static/avatar/user.svg'
import HistoryIcon from '../../public/static/history_black.svg'
import LoyaltyIcon from '../../public/static/avatar/loyalty.png'
import PremiumIcon from '../../public/static/avatar/premium.svg'
import ShopIcon from '../../public/static/avatar/shop.svg'
import {
  balanceUrl,
  blockssUrl,
  dashboardUrl,
  favoriteUrl,
  historysUrl,
  loyaltyUrl,
  settingProfilesUrl,
  settingsUrl,
  subscriptionUrl,
  tapinShopUrl,
} from '../../common/urls/settingsUrl'
import { useRecoilState } from 'recoil'
import { isOpenWalletState } from '../../common/atoms/checkout'
import { useTranslation } from 'next-i18next'
import { useGetTeammateStatus } from '../../hooks/dashboard/useGetTeammateStatus'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useSession } from 'next-auth/react'
import { useCreditAmount } from '../../hooks/currencies/useCurrencies'
import useLoyalty from '../../hooks/loyal/useLoyalty'
import Link from 'next/link'
import { useLogout } from '../../hooks/auth/useLogout'

export default function UserAvatar() {
  const { t } = useTranslation('common')
  const { user } = useUser()
  const { data: teammateStatus } = useGetTeammateStatus()
  const { status } = useSession()
  const [, setIsOpenWallet] = useRecoilState(isOpenWalletState)
  const { yourCreditString } = useCreditAmount()
  const { loyaltys, unranked } = useLoyalty()
  const { logoutCurrentSession } = useLogout()

  return (
    <div className={'dropdown dropdown-end dropdown-hover relative z-30 mr-0'}>
      <div
        tabIndex={0}
        className={`mb-1 mr-2 flex h-11 max-w-xs cursor-pointer select-none flex-row items-center gap-2 truncate rounded-full border ${user?.isProMembership ? 'border-gnar-400 bg-gnar-400 bg-opacity-40 hover:opacity-30' : 'border-fizz-500 bg-fizz-600 hover:bg-fizz-500'} pl-1 pr-4 md:mr-0`}
      >
        <div className={'relative h-9 w-9'}>
          <Image
            src={
              user?.avatar?.imageUrl ??
              process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
            }
            className={'rounded-full object-cover'}
            fill={true}
            alt={'User avatar'}
          />
        </div>

        {user && (
          <div className={'absolute h-6 w-6 bottom-0 left-5'}>
            <div className={'relative h-6 w-6'}>
              <Image
                src={
                  (
                    loyaltys.find((item) => {
                      return item.id === user?.rewardProgram?.tier.id
                    }) ?? unranked
                  ).icon
                }
                alt={'tags'}
                fill={true}
              />
            </div>
          </div>
        )}

        {user?.isProMembership && (
          <p className={'text-body-bold font-oswald text-gnar-400'}>PRO</p>
        )}

        <div className={'relative h-4 w-4'}>
          <Image
            src={MenuIcon}
            fill={true}
            className={'object-contain'}
            alt={'Menu icon'}
          />
        </div>
      </div>

      <ul
        tabIndex={0}
        className='dropdown-content menu w-60 bg-white px-1 pb-2 shadow-xl'
      >
        <Link href={settingProfilesUrl}>
          <div
            className={
              'flex cursor-pointer flex-row items-center gap-4 px-3 py-3 hover:opacity-70'
            }
          >
            <div className={'relative h-10 w-10 rounded-full '}>
              <Image
                src={
                  user?.avatar?.imageUrl ??
                  process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                }
                className={'rounded-full object-cover'}
                fill={true}
                alt={'User avatar'}
              />
            </div>

            <div className={'flex flex-col '}>
              <p
                className={
                  'text-body-medium w-36 truncate truncate text-fizz-1000'
                }
              >
                {user?.username ?? user?.fullName ?? ''}
              </p>
            </div>
          </div>
        </Link>

        {teammateStatus &&
          (teammateStatus.isSupplier || teammateStatus.isCandidate) && (
            <li>
              <Link href={dashboardUrl} className={'dropdown-link'}>
                <div
                  className={
                    'flex cursor-pointer flex-row items-center gap-3.5 py-2'
                  }
                >
                  <div className={'relative h-5 w-5'}>
                    <Image
                      src={DashboardIcon}
                      fill={true}
                      className={'object-contain'}
                      alt={'Dashboard icon'}
                    />
                  </div>

                  <p>{t('dashboardTab')}</p>
                </div>
              </Link>
            </li>
          )}

        <li>
          <Link href={loyaltyUrl} className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-3.5 py-2'
              }
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={LoyaltyIcon}
                  fill={true}
                  className={'object-contain text-blue-400'}
                  alt={'Loyalty icon'}
                />
              </div>

              <p>
                {t('rankTab')}:{' '}
                {
                  (
                    loyaltys.find((item) => {
                      return item.id === user?.rewardProgram?.tier.id
                    }) ?? unranked
                  )?.name
                }
              </p>
            </div>
          </Link>
        </li>

        {status === SessionStatusEnum.AUTHENTICATED && (
          <li className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-3.5 px-4 py-4'
              }
              onClick={(e) => {
                e.preventDefault()
                setIsOpenWallet(true)
              }}
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={WalletIcon}
                  className={'object-contain'}
                  fill={true}
                  alt={'Wallet icon'}
                />
              </div>

              <p className={'text-body-medium text-fizz-1000'}>
                {yourCreditString}
              </p>
            </div>
          </li>
        )}

        <li>
          <Link href={settingsUrl} className={'dropdown-link'}>
            <div
              className={'flex cursor-pointer flex-row items-center gap-4 py-2'}
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={PeopleIcon}
                  fill={true}
                  className={' text-blue-400'}
                  alt={'People icon'}
                />
              </div>

              <p>{t('profileTab')}</p>
            </div>
          </Link>
        </li>

        <li>
          <Link href={historysUrl} className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-3.5 py-2'
              }
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={HistoryIcon}
                  fill={true}
                  className={' text-blue-400'}
                  alt={'People icon'}
                />
              </div>
              <p>{t('historyTab')}</p>
            </div>
          </Link>
        </li>

        <li>
          <Link href={favoriteUrl} className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-3.5 py-3'
              }
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={HeartIcon}
                  className={'object-contain'}
                  fill={true}
                  alt={'Heart icon'}
                />
              </div>

              <p>{t('favoriteTeammates')}</p>
            </div>
          </Link>
        </li>

        <li>
          <Link href={blockssUrl} className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-2.5 py-2'
              }
            >
              <div className={'relative h-6 w-6'}>
                <Image
                  src={BlockedIcon}
                  className={'object-contain'}
                  fill={true}
                  alt={'Blocked icon'}
                />
              </div>

              <p>{t('blockedTeammates')}</p>
            </div>
          </Link>
        </li>

        <li>
          <Link href={balanceUrl} className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-3.5 py-2'
              }
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={BalanceIcon}
                  fill={true}
                  className={'object-contain'}
                  alt={'Balance icon'}
                />
              </div>
              <p>{t('reloadBalanceTab')}</p>
            </div>
          </Link>
        </li>

        {user?.isProMembership === true && (
          <li>
            <Link href={subscriptionUrl} className={'dropdown-link'}>
              <div
                className={
                  'flex cursor-pointer flex-row items-center gap-3.5 py-2'
                }
              >
                <div className={'relative h-5 w-5'}>
                  <Image
                    src={PremiumIcon}
                    fill={true}
                    className={' text-blue-400'}
                    alt={'Premium'}
                  />
                </div>
                <p>Subscription</p>
              </div>
            </Link>
          </li>
        )}

        {/*<li>*/}
        {/*  <Link href={shareUrl} className={'dropdown-link bg-[#BCF6F0]'}>*/}
        {/*    <div*/}
        {/*      className={*/}
        {/*        'flex cursor-pointer flex-row items-center gap-3.5 py-2'*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <div className={'relative h-5 w-5'}>*/}
        {/*        <Image*/}
        {/*          src={ShareIcon}*/}
        {/*          className={'object-contain'}*/}
        {/*          fill={true}*/}
        {/*          alt={'Share icon'}*/}
        {/*        />*/}
        {/*      </div>*/}

        {/*      <p>{t('referTab')}</p>*/}
        {/*    </div>*/}
        {/*  </Link>*/}
        {/*</li>*/}

        <li>
          <Link target='_blank' href={tapinShopUrl} className={'dropdown-link'}>
            <div
              className={
                'flex cursor-pointer flex-row items-center gap-3.5 py-2'
              }
            >
              <div className={'relative h-5 w-5'}>
                <Image
                  src={ShopIcon}
                  fill={true}
                  className={' text-blue-400'}
                  alt={'Shop'}
                />
              </div>
              <p>
                Shop <sup>Beta</sup>
              </p>
            </div>
          </Link>
        </li>

        <button
          className={
            'flex cursor-pointer flex-row items-center gap-3.5 px-3 py-4 dropdown-link'
          }
          onClick={async (e) => {
            e.preventDefault()
            logoutCurrentSession()
          }}
        >
          <div className={'relative h-5 w-5'}>
            <Image
              src={SignOffIcon}
              className={'object-contain'}
              fill={true}
              alt={'Sign out icon'}
            />
          </div>

          <p className={'text-body-medium text-fizz-1000'}>{t('signOutTab')}</p>
        </button>
      </ul>
    </div>
  )
}
