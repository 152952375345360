import Image from 'next/image'
import Logo from '../../public/static/logo_navbar.png'
import { useSession } from 'next-auth/react'
import UserAvatar from '../auth/UserAvatar'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useRecoilState } from 'recoil'
import {
  isOpenGuestSignUpModalState,
  isOpenLogInModalState,
  isOpenSignUpModalState,
} from '../../common/atoms/checkout'
import { balanceUrl, loyaltyUrl } from '../../common/urls/settingsUrl'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { AuthButtonsV2 } from '../auth/AuthButtonV2'
import GuestSignUpModal from '../auth/modals/GuestSignUpModal'
import { useUser } from '../../hooks/users/useUser'
import { defaultCurrencyState } from '../../common/atoms/atoms'
import { useGetCurrencies } from '../../hooks/currencies/useGetCurrencies'
import Cookies from 'js-cookie'
import { useDefaultCurrency } from '../../hooks/currencies/useDefaultCurrency'
import GetProButton from '../buttons/GetProButton'

const SignUpModal = dynamic(() => import('../auth/modals/SignUpModal'), {
  ssr: true,
})

const LogInModal = dynamic(() => import('../auth/modals/LogInModal'), {
  ssr: true,
})

type Props = {
  isSticky?: boolean
}
export default function HeaderV2({ isSticky }: Props) {
  const { status } = useSession()
  const [isOpenSignUpModal] = useRecoilState(isOpenSignUpModalState)
  const [isOpenLogInModal] = useRecoilState(isOpenLogInModalState)
  const [isOpenGuestSignUpModal] = useRecoilState(isOpenGuestSignUpModalState)
  const { user } = useUser()
  const { data: currencies } = useGetCurrencies(Cookies.get('currency'))
  const [currency, setCurrency] = useRecoilState(defaultCurrencyState)
  const { updateCurrency } = useDefaultCurrency()

  return (
    <header
      className={`top-0 z-30 ${
        isSticky
          ? 'fixed w-full shadow-lg bg-nav border-b border-darul-600 py-3'
          : 'bg-transparent py-3'
      }`}
    >
      <div
        className={`flex ${
          isSticky
            ? 'h-12 items-center justify-between md:mx-12'
            : 'h-12 items-center justify-between md:mx-12'
        }`}
      >
        <div className='flex items-center gap-10 mx-4 md:mx-0'>
          <Link href='/' className='cursor-pointer'>
            <Image
              src={Logo}
              height={31}
              width={100}
              className={'object-contain'}
              alt={'Logo icon'}
            />
          </Link>
        </div>

        {/* Right */}
        <div className='flex items-center gap-1'>
          {currencies && currency && (
            <div className='dropdown dropdown-hover cursor-pointer'>
              <div
                tabIndex={0}
                className={
                  'flex-row items-center gap-1.5 justify-center hidden md:flex'
                }
              >
                <div className={'h-4 w-4 relative'}>
                  <Image
                    src={
                      currency?.imageUrl ??
                      process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!
                    }
                    fill={true}
                    className={'object-cover rounded-full'}
                    alt={'Currency icon'}
                  />
                </div>

                <div className='text-body-medium font-oswald uppercase my-2 mr-5 text-fizz-100'>
                  {`${currency?.iso4217Code?.toUpperCase() ?? 'USD'} - ${
                    currency?.currencySymbol ?? '$'
                  }`}
                </div>
              </div>

              <ul
                tabIndex={0}
                className='dropdown-content z-[1] menu px-4 py-2 bg-white w-30'
              >
                {currencies.map((currency) => {
                  return (
                    <button
                      key={currency.id}
                      className={
                        'flex-row items-center gap-1.5 flex hover:opacity-60 cursor-pointer'
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        if (status === SessionStatusEnum.AUTHENTICATED)
                          updateCurrency({ currencyId: currency.id })
                        setCurrency(currency)
                        Cookies.set('currency', `${currency.id}`)
                      }}
                    >
                      <div className={'h-4 w-4 relative'}>
                        <Image
                          src={
                            currency?.imageUrl ??
                            process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!
                          }
                          fill={true}
                          className={'object-cover rounded-full'}
                          alt={'Currency icon'}
                        />
                      </div>

                      <div className='text-body-medium my-2 text-black'>
                        {`${currency?.iso4217Code?.toUpperCase() ?? 'USD'} - ${
                          currency?.currencySymbol ?? '$'
                        }`}
                      </div>
                    </button>
                  )
                })}
              </ul>
            </div>
          )}

          {/*<Link*/}
          {/*  className='text-body-semibold my-2 mr-4 hidden cursor-pointer text-[#C3FF00] md:flex'*/}
          {/*  href={balanceUrl}*/}
          {/*>*/}
          {/*  Holiday Sales*/}
          {/*</Link>*/}

          <div className={'relative my-2 mr-5 hidden md:flex'}>
            <Link
              className='text-body-medium font-oswald uppercase cursor-pointer text-fizz-100 hover:text-white'
              href={loyaltyUrl}
            >
              Rewards
            </Link>
            {/*<div*/}
            {/*  className={*/}
            {/*    'absolute top-0 -right-2 bg-bung-400 w-2.5 h-2.5 rounded-full '*/}
            {/*  }*/}
            {/*></div>*/}
          </div>

          <Link
            className='text-body-medium font-oswald uppercase my-2 mr-5 hidden cursor-pointer text-fizz-100 hover:text-white md:flex'
            href={balanceUrl}
          >
            Buy Credits, Save More!
          </Link>

          {status === SessionStatusEnum.AUTHENTICATED &&
            user?.isProMembership !== true && <GetProButton />}

          {status === SessionStatusEnum.LOADING ? (
            <div />
          ) : status === SessionStatusEnum.AUTHENTICATED ? (
            <UserAvatar />
          ) : (
            <AuthButtonsV2 />
          )}
        </div>
      </div>

      {status !== SessionStatusEnum.AUTHENTICATED && isOpenSignUpModal && (
        <SignUpModal />
      )}

      {status !== SessionStatusEnum.AUTHENTICATED && isOpenLogInModal && (
        <LogInModal />
      )}

      {user?.isGuest && isOpenGuestSignUpModal && <GuestSignUpModal />}
    </header>
  )
}
