export const dashboardUrl = '/dashboard'
export const settingsUrl = '/settings'
export const historysUrl = '/history'
export const settingProfilesUrl = '/settings/profile'
export const blockssUrl = '/blocks'
export const favoriteUrl = '/favorites'
export const shareUrl = '/share'
export const balanceUrl = '/balance'
export const loyaltyUrl = '/loyalty-program'
export const rewardsUrl = '/rewards'
export const subscriptionUrl = '/subscriptions'
export const tapinShopUrl = 'https://shop.tapin.gg/'
