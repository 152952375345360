import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RegexUtils } from '../../../common/utils/regex.utils'
import {
  ErrorResponse,
  readableError,
} from '../../../types/errors/responses/error-response'
import { toast } from 'react-toastify'
import { useTranslation } from 'next-i18next'
import { CreateUserRequest } from '../../../types/auth/requests/create-user-request'
import { useRecoilState } from 'recoil'
import { isOpenGuestSignUpModalState } from '../../../common/atoms/checkout'
import Divider from '../../divider/Divider'
import ModalCloseButton from '../../common/modals/ModalCloseButton'
import { useConvertGuestToUser } from '../../../hooks/auth/useConvertGuestToUser'
import { LoginRequest } from '../../../types/auth/requests/login-request'
import { GuestOauthContainer } from './GuestOauthContainer'
import { useUser } from '../../../hooks/users/useUser'
import Link from 'next/link'

export default function GuestSignUpModal() {
  const [isOpen, setIsOpen] = useRecoilState(isOpenGuestSignUpModalState)
  const { t } = useTranslation('common')
  const { mutate, isLoading, isSuccess, error } = useConvertGuestToUser()
  const { refetch } = useUser()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit: SubmitHandler<CreateUserRequest> = async (data) => {
    if (isLoading || isSuccess) return
    mutate({
      grantType: 'client_credentials',
      username: data.email,
      password: data.password,
    } as LoginRequest)
  }

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false)
      refetch()
    }
  }, [isSuccess])

  useEffect(() => {
    if (error) {
      toast.error(readableError(error as ErrorResponse))
    }
  }, [error])

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-30' onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='modal-mask' />
          </Transition.Child>

          <div className={'fixed inset-0 z-10 overflow-y-auto'}>
            <div
              className={
                'flex min-h-full items-center justify-center text-center sm:p-0'
              }
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='modal-dialog'>
                  {/* ModalBody */}

                  <ModalCloseButton isOpenState={isOpenGuestSignUpModalState} />

                  <div
                    className={'flex w-full flex-col items-center px-4 sm:px-8'}
                  >
                    <h2 className='modal-title'>WELCOME TO TAPIN</h2>

                    <div className={'mb-6 mt-1 flex flex-row gap-1'}>
                      <p className={'text-body-normal text-fizz-1000'}>
                        Please fill out the form below to continue
                      </p>
                    </div>

                    {/* sign up form */}
                    <form
                      className='flex w-full flex-col gap-4'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className={'relative '}>
                        <input
                          id={'email'}
                          type={'email'}
                          placeholder={' '}
                          className={`input-basic-v2 peer ${
                            errors.email ? 'ring-1 ring-inset ring-red-400' : ''
                          }`}
                          {...register('email', {
                            pattern: RegexUtils.email,
                            required: true,
                          })}
                        />
                        <label
                          htmlFor={'email'}
                          className={'input-basic-label'}
                        >
                          Email
                        </label>
                        {errors.email && (
                          <span className={'text-error'}>
                            {t('inputFieldInvalidError')}
                          </span>
                        )}
                      </div>

                      <div className={'relative'}>
                        <input
                          id={'password'}
                          type={'password'}
                          placeholder={' '}
                          className={`input-basic-v2 peer ${
                            errors.password
                              ? 'ring-1 ring-inset ring-red-400'
                              : ''
                          }`}
                          {...register('password', {
                            required: true,
                          })}
                        />
                        <label
                          htmlFor={'password'}
                          className={'input-basic-label'}
                        >
                          {t('passwordLabelText')}
                        </label>
                        {errors.password && (
                          <span className={'text-error'}>
                            {t('inputFieldInvalidError')}
                          </span>
                        )}
                      </div>

                      {/* Button */}
                      <button type='submit' className={'btn-v2-primary'}>
                        {(isLoading || isSuccess) && (
                          <span className='loading loading-spinner' />
                        )}
                        {t('continueBtn')}
                      </button>
                    </form>

                    {/* Divider */}
                    <div className={'my-7 w-full'}>
                      <Divider text={'OR'} textBackgroundColor={'bg-white'} />
                    </div>

                    <GuestOauthContainer />

                    <p className='mt-6 text-center text-xs font-medium text-black'>
                      By registering for this app, you accept our{' '}
                      <Link
                        className={'font-bold underline'}
                        href='/legal/terms'
                      >
                        Terms of Use
                      </Link>{' '}
                      and our{' '}
                      <Link
                        className={'font-bold underline'}
                        href='/legal/privacy'
                      >
                        Privacy Policy
                      </Link>
                      .
                    </p>

                    {/* Close button */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
